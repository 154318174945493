<template>
  <common-anggota-create-update
    :headerText="headerText"
    :anggotaMdl="preceptorMdl"
  >
    <!-- <b-field
      label="Mulai"
      custom-class="is-small"
      :type="{'is-danger': errorMap.mulai}"
      :message="errorMap.mulai"
    >
      <date-picker v-model="anggota.mulai" @input="validateInput('mulai')"></date-picker>
    </b-field> -->

    <b-field
      label="Departemen"
      custom-class="is-small"
      :type="{'is-danger': errorMap.sub_departemen}"
      :message="errorMap.sub_departemen"
    >
      <generic-select
        v-model="anggota.sub_departemen"
        apiPath="/programstudi/subdepartemen/"
        @input="validateInput('sub_departemen')"
      ></generic-select>
    </b-field>
  </common-anggota-create-update>
</template>


<script>
import { has } from "lodash";
import Konsulen from "../models/preceptor.js";


export default {
  name: "PreceptorCreateUpdate",
  props: ["headerText"],
  components: {
    CommonAnggotaCreateUpdate: () =>
      import("@/apps/anggota/views/CommonAnggotaCreateUpdate.vue"),
    // DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue")
  },
  data() {
    this.preceptorMdl = new Konsulen("anggota");
    return this.preceptorMdl.getObservables();
  },
  computed: {
    isCreate() {
      return !has(this.$route.params, "id");
    }
  },
  methods: {
    validateInput(field) {
      this.preceptorMdl.validate(field);
    }
  }
};
</script>
