import AnggotaBase from "./anggotaBase.js"

const modelName = "preceptor";
const modelPath = "/anggota/";

class Preceptor extends AnggotaBase {
  constructor(overrideName) {
    let model = {
      sub_departemen: null
    };
    let requiredFields = ["sub_departemen"];
    let ovrName = overrideName ? overrideName : modelName;
    super(ovrName, model, requiredFields, [], modelPath);
  }

  initObservables() {
    super.initObservables();
    this.observables.tipe = "preceptor";
  }

  getLoadData(data) {
    let tdata = super.getLoadData(data);
    tdata.sub_departemen = data.preceptor.sub_departemen.id;
    return tdata;
  }
}

export default Preceptor;